
import { defineComponent, PropType } from "vue";

import QuilityAPI from "@/store/API/QuilityAPI.js";
import type { SummitQuizBlock } from "@/CMS/types/blocks";

import TextEditor from "../TextEditor.vue";

type ResponseQuiz = {
	FormTitle: string;
	FormType: string;
	ID: number;
	Slug: string;
};

type Response<D> = {
	data: D;
	meta: unknown;
};

export default defineComponent({
	components: {
		TextEditor,
	},

	props: {
		value: {
			type: Object as PropType<SummitQuizBlock["block"]>,
			required: true,
		},
	},

	emits: { input: (v: SummitQuizBlock["block"]) => !v },

	data() {
		return {
			quizList: [] as ResponseQuiz[],
		};
	},

	mounted() {
		QuilityAPI.getQuizzes()
            .then(resp => {
                // currently don't need all the data the resource returns
                this.quizList = resp.data;
            })
            .catch(err => {
                this.error = err.msg;
                setTimeout(() => this.error = null, 6000);
            });
	},

	computed: {
		quizId() {
			return this.quizList.find((quiz) => quiz.Slug === this.value.Quiz.quiz_slug)?.ID;
		},
		quizSlug: {
			get() {
				return this.value.Quiz.quiz_slug;
			},
			set(v: string) {
				this.$emit("input", {
					Quiz: {
						...this.value?.Quiz,
						quiz_slug: v,
					},
				});
			},
		},
		text: {
			get() {
				return this.value?.Quiz.text;
			},
			set(v: string) {
				this.$emit("input", {
					Quiz: {
						...this.value?.Quiz,
						text: v,
					},
				});
			},
		},
	},
});
