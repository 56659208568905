<template>
    <div>
        <v-btn color="blue" @click="showGondolaRide = true">Gondola Ride</v-btn>

        <v-dialog v-model="showGondolaRide" width="50%" persistent>
            <v-card>
                <v-card-title>
                    Gondola Ride
                    <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
                </v-card-title>
                <v-card-text>
                    <q-agent-lookup label="Agent" v-model="agentCode"
                                    :filters="{Status: 'Active,Available' }"
                    ></q-agent-lookup>
                    <v-select label="Step"
                              v-model="stepId"
                              :items="steps" item-text="StepTitle" item-value="ID"
                              hint="Complete all steps between agent's current step and this one, including this one."
                              persistent-hint
                    ></v-select>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" @click="rideTheGondola">Go</v-btn>
                    <v-btn color="red" @click="showGondolaRide = false">Cancel</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>
    </div>
</template>

<script>
import QAgentLookup from "@/components/utils/QAgentLookup";
import QuilityAPI from "@/store/API/QuilityAPI";

export default {
    name: "QGondolaRide",

    props: {
        'steps': {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            agentCode: null,
            showGondolaRide: false,
            stepId: null,
            loading:false
        };
    },

    methods: {
        rideTheGondola() {
            this.loading = true;
            QuilityAPI.summitGondolaRide(this.agentCode, this.stepId)
                .then(resp => {
                    this.showSuccess('Ride complete!');
                    this.agentCode = null;
                    this.stepId = null;
                })
                .catch(err => {
                    this.showError(err.msg || err.message);
                })
                .finally(() => this.loading = false);

        },
    },

    components: {
        QAgentLookup
    },
}
</script>

<style scoped>

</style>
