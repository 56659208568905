
import { defineComponent, PropType } from "vue";

import type {
	SummitLightSpeedVideoBlock,
	SummitWistiaVideoBlock,
	SummitQuizBlock,
	FullWidthTextBlock,
} from "../../CMS/types/blocks";

import WistiaVideoGroupEditor from "@/CMS/editor/components/editors/SummitWistiaVideoGroupEditor.vue";
import SummitQuizEditor from "@/CMS/editor/components/editors/SummitQuizEditor.vue";
import FullWidthTextEditor from "@/CMS/editor/components/editors/FullWidthTextEditor.vue";
import LightSpeedVideoGroupEditor from "@/CMS/editor/components/editors/LightSpeedVideoGroupEditor.vue";

type SummitBlock =
	| FullWidthTextBlock
	| SummitWistiaVideoBlock
	| SummitLightSpeedVideoBlock
	| SummitQuizBlock;
type SummitBlockContentType = SummitBlock["type"];

const content_types: {
	text: string;
	value: SummitBlockContentType;
}[] = [
	{
		text: "Wistia Video",
		value: "wistia-video-group",
	},
	{
		text: "Text",
		value: "full-width-text",
	},
	{
		text: "LightSpeed Video",
		value: "light-speed-video-group",
	},
	{
		text: "Quiz",
		value: "summit-quiz",
	},
];

const getBlockByType = (type: SummitBlockContentType | 'calculator') => {
	switch (type) {
		case "wistia-video-group":
			return {
				Videos: [
					{
						text: "",
						title: "",
						video_id: "",
					},
				],
				NumColumns: 1,
			};
		case "full-width-text":
			return {
				text: "Put some copy here.",
				Title: "Need Another Title?",
			};
		case "light-speed-video-group":
			return {
				Videos: [
					{
						text: "",
						title: "",
						token: "",
						video_id: "",
					},
				],
			};
		case "summit-quiz":
			return {
				Quiz: {
					text: "",
					quiz_slug: "",
					resources: "",
				},
			};
        case "calculator":
            return {}
		default:
			return null
	}
};

export default defineComponent({
	name: "QStepContentEditor",

	components: {
		WistiaVideoGroupEditor,
		FullWidthTextEditor,
		LightSpeedVideoGroupEditor,
        SummitQuizEditor,
	},

	props: {
		value: {
			type: Object as PropType<SummitBlock | null>,
			required: true,
		},
	},

	emits: { input: (v: SummitBlock | Record<never, unknown> | null) => !!v },

	data() {
		return {
			saving: false,
			warning: null,
			error: null,
			valid: false,
			content_types,
		};
	},

	computed: {
		content_type: {
			get(): SummitBlockContentType | "" {
				return this.value?.type || "";
			},
			set(newContentType: SummitBlockContentType) {
                this.$emit("input", {
                    type: newContentType,
                    block: getBlockByType(newContentType),
                });
			},
		},
	},
});
