<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0 cms">
        <v-row wrap>
            <v-col cols=5>
                <v-row class="mx-5">
                    <v-col cols=12>
                        <v-row class="pr-5">
                            <h2>Summit: Manage Steps
                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon v-bind="attrs" v-on="on" @click="getAllSteps">
                                            <v-icon>fas fa-redo-alt</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Revert to last saved version</span>
                                </v-tooltip>
                            </h2>
                            <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
                        </v-row>
                        <v-btn color="primary" @click="saveOrder">
                            Save Changes
                        </v-btn>
                        <q-gondola-ride class="d-inline-block mx-2"
                                        :steps="savedSteps"
                        ></q-gondola-ride>
                    </v-col>
                </v-row>
                <v-row class="mx-5">
                    <v-col cols="12">
                        <SummitPhaseFilter v-model="selectedPhase" />
                    </v-col>
                </v-row>
                <v-row class="mx-5" v-if="steps.length > 0">
                    <v-col cols=12>
                        <draggable tag="div" :list="steps" class="v-list" @change="onDragChange">
                            <div v-for="(step, ix) in steps" :key="step.ID">
                                <v-list-item-group :class="step.EnabledInd == 1 ? 'elevation-2 mb-2 background-white' : 'mb-2 grey lighten-2 disabled'">
                                    <v-list-item>
                                        <v-list-item-icon width="30px">
                                            <v-img v-if="step.StepIcon" max-width="30px" max-height="30px" contain :src="step.StepIcon"></v-img>
                                        </v-list-item-icon>
                                        <v-list-item-icon width="30px">
                                            <v-icon color="grey lighten-2" class="pr-3">fas fa-arrows-alt</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-subtitle>#{{step.StepOrder + 1}} {{ step.StepCategory }} : <em>{{ step.StepType }}</em></v-list-item-subtitle>
                                            <v-list-item-title>
                                                <strong><a @click.stop="viewStep(ix)">{{ step.StepTitle }}</a></strong>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-action class="text-center">
                                            <v-btn icon @click.stop="editStep(ix)">
                                                <v-icon>fas fa-edit</v-icon>
                                            </v-btn>
                                        </v-list-item-action>
                                        <v-list-item-action class="text-center">
                                            <v-btn icon @click.stop="previewStep(ix)">
                                                <v-icon right>fas fa-eye</v-icon>
                                            </v-btn>
                                        </v-list-item-action>
                                        <v-list-item-action class="text-center">
                                            <v-btn icon @click.stop="confirmDeletePopup(ix)">
                                                <v-icon>fas fa-trash</v-icon>
                                            </v-btn>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-list-item-group>
                                <div class="text-center">
                                    <v-btn @click="addStep(ix)" text>
                                        <v-icon left>fas fa-plus-circle</v-icon> Add Step
                                    </v-btn>
                                </div>
                            </div>
                        </draggable>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols=7 v-if="currentStep.ID">
                <v-row class="mx-5">
                    <v-col cols=12>
                        <h2>Preview Step
                            <v-btn icon @click.stop="editStep(editIndex)">
                                <v-icon>fas fa-edit</v-icon>
                            </v-btn>
                        </h2>
                        <p>You can preview any step, or the last step edited will show up here.</p>
                        <div :key="'preview_' + currentStep.ID" v-if="currentStep.StepConfig.content && currentStep.StepConfig.content.block">
                            <v-btn :to="currentStep.Url" target="_blank">
                                Open in new tab
                            </v-btn>
                            <h1 style="position: relative;">
                                <q-compass-progress style="position: absolute; top: -75px; right: 0; height:175px; width:175px;" :final-number="sample_progress"></q-compass-progress>{{currentStep.StepTitle}}
                            </h1>
                            <div style="clear:both;" :is="currentStep.StepConfig.content.type" :content-data="currentStep.StepConfig.content.block"></div>
                        </div>
                    </v-col>
                    <v-col cols="3">
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-dialog v-if="steps[editIndex]" v-model="showEditStep" width="80%" persistent :key="'editor_dialog' + steps[editIndex].ID">
            <q-edit-step :key="'editor' + steps[editIndex].ID" v-on:close="closeEditStep" v-on:saved="stepSaved" :step="currentStep"></q-edit-step>
        </v-dialog>
        <v-dialog v-model="showDeleteConfirmation" width="40%">
            <v-card>
                <v-card-title>Delete this Step?</v-card-title>
                <v-card-text>
                    <p>Are you sure you want to delete this step? All agents will proceed to the next level and currently scheduled alerts will be canceled!</p>
                    <v-progress-linear indeterminate v-if="delete_loading"></v-progress-linear>
                    <div class="text-center">
                        <v-btn color="red" @click="deleteStep" class="mr-5">Delete Step</v-btn>
                        <v-btn @click="showDeleteConfirmation = false">Cancel</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="notice.visible" top :color="notice.color" multi-line :timeout="6000">
            {{ notice.text }}
            <v-btn text @click="notice.visible = false">
                Close
            </v-btn>
        </v-snackbar>
    </v-container>
</template>
<script>
import draggable from "vuedraggable";
import QuilityAPI from '@/store/API/QuilityAPI.js';
import QEditStep from "@/components/summit/QEditStep";
import FullWidthText from '@/CMS/views/ContentBlocks/FullWidthText.vue'
import WistiaVideoGroup from '@/CMS/views/ContentBlocks/WistiaVideoGroup.vue'
import LightSpeedVideoGroup from '@/CMS/views/ContentBlocks/LightSpeedVideoGroup.vue'
import QCompassProgress from "@/components/summit/QCompassProgress.vue";
import QGondolaRide from "@/components/summit/QGondolaRide";
import SummitPhaseFilter from "@/components/summit/admin/SummitPhaseFilter.vue";

export default {
    name: "ManageSummit",

    props: [],

    components: {
        QGondolaRide,
        QEditStep,
        SummitPhaseFilter,
        draggable,
        FullWidthText,
        WistiaVideoGroup,
        QCompassProgress,
        LightSpeedVideoGroup
    },

    data() {
        return {
            loading: false,
            unfilteredSteps: [],
            currentStep: {
                ID: null,
                EnabledInd: true,
                StepConfig: {
                    requirements: [],
                    content: null
                },
                alerts: [],
            },
            deleteStepIndex: null,
            delete_loading: false,
            showEditStep: false,
            showDeleteConfirmation: false,
            editIndex: 0,
            sample_progress: 45,
            selectedPhase: 'basecamp'
        };
    },

    mounted() {
        this.getAllSteps();
    },

    methods: {
        getAllSteps() {
            this.loading = true;
            this.unfilteredSteps = [];
            QuilityAPI.summitGetSteps()
                .then((resp) => {
                    this.$set(this, 'unfilteredSteps', resp.data);
                    this.unfilteredSteps.sort(function(a, b) {
                        return a.StepOrder - b.StepOrder;
                    });
                    this.loading = false;
                })
                .catch((err) => {
                    this.showError(err.msg || err.message)
                    this.loading = false;
                });
        },

        editStep(i) {
            var step = { ...this.steps[i] }
            this.hydrateStep(step);
            this.currentStep = step;
            this.editIndex = i;
            this.showEditStep = true;
        },

        previewStep(i) {
            var step = { ...this.steps[i] }
            this.hydrateStep(step);
            this.currentStep = step;
            this.editIndex = i;
            this.$vuetify.goTo(0, {
                duration: 250,
                easing: 'easeOutCubic',
            });
            var num_steps_in_category = 0;
            var step_progress_count = 0;
            this.steps.forEach(function(s) {
                if (s.StepCategory == step.StepCategory) {
                    num_steps_in_category = num_steps_in_category + 1;
                }
                if (s.ID == step.ID) {
                    step_progress_count = num_steps_in_category - 1
                }
            })
            this.sample_progress = Math.round(step_progress_count / num_steps_in_category * 100)
        },

        viewStep(i) {
            this.$router.push({ path: this.steps[i].Url })
        },

        hydrateStep(step) {
            if (typeof step.StepConfig == 'undefined' || step.StepConfig == null || step.StepConfig == "null" || step.StepConfig == '"null"') {
                step.StepConfig = {
                    alerts: [],
                    requirements: [],
                    content: null
                }
            }
            if (typeof step.StepConfig.content == 'undefined') {
                step.StepConfig.content = null
            }
            if (typeof step.StepConfig.requirements == 'undefined') {
                step.StepConfig.requirements = []
            }
            if (typeof step.StepConfig.alerts == 'undefined') {
                step.StepConfig.alerts = []
            }
        },

        saveOrder() {
            let that = this;
            this.loading = true;
            QuilityAPI.summitSaveStepOrder(this.unfilteredSteps)
                .then(function(resp) {
                    that.unfilteredSteps = resp.data;
                    that.loading = false;
                })
                .catch(function(err) {
                    that.showError(err.msg || err.message)
                    that.loading = false;
                });
        },

        addStep(ix) {
            let newStep = {
                ID: null,
                EnabledInd: true,
                StepOrder: ix + 1,
                StepConfig: {
                    requirements: [],
                    content: null
                },
                alerts: [],
            };
            this.steps.splice(newStep.StepOrder, 0, newStep);
            this.editStep(newStep.StepOrder);

        },

        stepSaved(savedStep) {
            if (savedStep.ID == null) {
                array.splice(this.editIndex, 1);
                return;
            }
            this.saveOrder()
            // respond to event from step edit component
            this.hydrateStep(savedStep)
            this.$set(this.steps, this.editIndex, savedStep);
            this.currentStep = savedStep;
            //this.$set(this, 'editIndex', null);
            this.showEditStep = false;
            this.previewStep(this.editIndex)
        },

        closeEditStep() {
            // remove step if a new one was added and then canceled
            if (this.steps[this.editIndex].ID == null) {
                this.steps.splice(this.editIndex, 1);
            }
            this.showEditStep = false;
        },

        confirmDeletePopup(ix) {
            this.showDeleteConfirmation = true
            this.deleteStepIndex = ix
        },

        deleteStep() {
            let that = this;
            this.delete_loading = true;
            QuilityAPI.summitDeleteStep(this.steps[this.deleteStepIndex].ID)
                .then(function(resp) {
                    that.unfilteredSteps = resp.data;
                    that.delete_loading = false;
                    that.deleteStepIndex = null
                    that.showDeleteConfirmation = false;
                })
                .catch(function(err) {
                    that.showError(err.msg || err.message)
                    that.loading = false;
                });
        },

        onDragChange({ moved: { newIndex, oldIndex }}) {
            const el = this.steps[newIndex];
            const insertAfterEl = this.steps[newIndex - 1];

            const elIndex = this.unfilteredSteps.indexOf(el)
            let insertAfterElIndex = this.unfilteredSteps.indexOf(insertAfterEl)

            if (newIndex < oldIndex) {
                insertAfterElIndex += 1
            }

            this.unfilteredSteps.splice(elIndex, 1);
            this.unfilteredSteps.splice(insertAfterElIndex, 0, el);
        }
    },

    computed: {
        steps() {
            return this.unfilteredSteps
                .filter((step) => step.StepCategory.toLowerCase().replaceAll(/ |-/g, '') == this.selectedPhase)
        },
        notice() {
            return this.$store.state.entities.page.notice;
        },
        'summit_nav': function() {
            return SummitNav.all()
        },
        savedSteps() {
            return this.steps.filter(step => step.ID !== null);
        }
    },
}
</script>

<style scoped>
.disabled {
    opacity: .25;
}
</style>
