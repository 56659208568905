<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0 cms">
        <v-row wrap>
            <v-col cols=12>
                <q-compass-progress style="height:175px; width:175px;" :total-steps="summit_nav[4].StepsInCategory" :current-step="summit_nav[4].StepCategoryOrder"></q-compass-progress>
                <div v-for="(step, ix) in summit_nav" :key="step.ID">
                    <v-list>
                        <v-list-item @click="$router.push({ path: step.Url })">
                            <v-list-item-icon width="30px">
                                <v-img max-width="30px" max-height="30px" contain :src="step.StepIcon"></v-img>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-subtitle>Step {{step.StepOrder + 1}} {{step.StepComplete}}</v-list-item-subtitle>
                                <v-list-item-title>
                                    <strong>{{ step.StepTitle }}</strong>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import SummitNav from '@/store/Models/SummitNav'
import QCompassProgress from "@/components/summit/QCompassProgress.vue";

export default {
    props: [],

    data() {
        return {

        };
    },

    mounted() {

    },

    methods: {

    },

    computed: {
        'summit_nav': function() {
            return SummitNav.query().orderBy('StepOrder').get()
        }
    },

    components: {
        QCompassProgress
    },

    watch: {

    }

}

</script>
