<template>
    <v-card>
        <v-card-title>Edit Step</v-card-title>
        <v-card-text>
            <v-progress-linear indeterminate v-if="saving"></v-progress-linear>
            <v-form v-model="valid" ref="form" :disabled="saving">
                <v-tabs dark>
                    <v-tab>Configuration</v-tab>
                    <v-tab>Content</v-tab>
                    <v-tab>Requirements</v-tab>
                    <v-tab>Alerts</v-tab>
                    <v-tab-item eager>
                        <v-row wrap dense>
                            <v-col cols="12">
                                <v-text-field label="Title" v-model="editStep.StepTitle" :rules="[form_rules.required]"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-select :items="types" v-model="editStep.StepType" label="Type" :rules="[form_rules.required]"></v-select>
                            </v-col>
                            <v-col cols="6">
                                <v-select :items="categories" v-model="editStep.StepCategory" label="Phase" :rules="[form_rules.required]"></v-select>
                            </v-col>
                            <v-col cols="4">
                                <v-checkbox label="Optional / Challenge" v-model="editStep.OptionalInd"></v-checkbox>
                            </v-col>
                            <v-col cols="4">
                                <v-checkbox label="Enabled" v-model="editStep.EnabledInd"></v-checkbox>
                            </v-col>
                            <v-col cols="4" v-if="editStep.ID != null">
                                <v-text-field label="Slug" v-model="editStep.Slug" :rules="[form_rules.required]"></v-text-field>
                            </v-col>
                            <v-col cols="4">
                                Step Icon
                                <image-selector label="Step Icon" :key="'step-icon-selector-' + editStep.ID" height="100px" width="100px" :aspect-ratio="1" :show-image="true" v-model="editStep.StepIcon">
                                </image-selector>
                            </v-col>
                        </v-row>
						<v-row>
							<v-col cols="4">
                                Badge Image - Full Size
                                <image-selector :key="'step-badge-selector-' + editStep.ID" height="100px" width="100px" :aspect-ratio="1" label="Badge Image - Full Size" :show-image="true" v-model="editStep.StepBadge"></image-selector>
                            </v-col>
                            <v-col cols="4">
                                Badge Image - Micro
                                <image-selector :key="'step-badge-micro-selector-' + editStep.ID" height="100px" width="100px" :aspect-ratio="1" label="Badge Image - Micro Size" :show-image="true" v-model="editStep.StepBadgeMicro"></image-selector>
                            </v-col>
							<v-col cols="4">
                                Badge Image - Description
                                <v-text-field label="Description" v-model="editStep.StepBadgeDescription"></v-text-field>
                            </v-col>
						</v-row>
                    </v-tab-item>
                    <v-tab-item eager>
                        <v-row wrap dense>
                            <v-col cols="12">{{'editstep-' + editStep.ID}}
                                <q-step-content-editor :key="'editstep-' + editStep.ID" v-model="editStep.StepConfig.content"></q-step-content-editor>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item eager>
                        <v-row wrap dense>
                            <v-col cols="12">
                                <p>Add Requirement</p>
                                <v-select :items="requirementTypes" v-model="newRequirement.type" label="Requirement Type"></v-select>
                                <div v-if="newRequirement.type == 'Popup'">
                                    This requirement type will display a yes or no popup to the agent.
                                    <v-text-field label="Title" v-model="newRequirement.popup.title"></v-text-field>
                                    <v-text-field label="Question" v-model="newRequirement.popup.question"></v-text-field>
                                </div>
                                <div v-if="newRequirement.type == 'Approved Apps'">
                                    This requirement type will check if the agent has the given number of approved apps.
                                    <v-text-field label="Number of apps" type="number" v-model="newRequirement.apps.count"></v-text-field>
                                </div>
                                <div v-if="newRequirement.type == 'Video'">
                                    This requirement type will check if the agent has fully watched the video in Wistia.
                                    <v-text-field label="Media ID" v-model="newRequirement.video.id"></v-text-field>
                                </div>
                                <div v-if="newRequirement.type == 'Lightspeed Video'">
                                    This requirement type will check if the agent has fully watched the video in Light Speed.
                                    <v-text-field label="Video Campaign ID" v-model="newRequirement.lightspeed_video.cmp_id"></v-text-field>
                                </div>
                                <div v-if="newRequirement.type == 'Leadership Level'">
                                    This requirement type will check if the agent has attained a leadershipship level or above.
                                    <v-select label="Leadership Level" :items="leadership_levels" item-value="LevelName" item-text="LevelName" v-model="newRequirement.leadership_level.leadership_level" prepend-icon="fas fa-crown"></v-select>
                                </div>
                                <div v-if="newRequirement.type == 'Contract Level'">
                                    This requirement type will check if the agent has attained a leadershipship level or above.
                                    <v-select label="Contract Level" :items="contract_levels" item-value="LevelName" item-text="LevelName" v-model="newRequirement.contract_level.contract_level" prepend-icon="fas fa-crown"></v-select>
                                </div>
                                <div v-if="newRequirement.type == 'Overrides'">
                                    This requirement type will check if the agent has the given number of approved apps.
                                    <v-text-field label="Number of overrides" type="number" v-model="newRequirement.overrides.count"></v-text-field>
                                </div>
                                <div v-if="newRequirement.type == 'Quiz'">
                                    This requirement type will check if the agent has a passing grade for the given quiz.
                                    <v-select label="Quiz" :items="availableQuizzes" item-text="name" v-model="newRequirement.quiz" :return-object="true"></v-select>
                                </div>
                                <v-btn @click="addRequirement">Add Requirement</v-btn>
                            </v-col>
                        </v-row>
                        <v-row v-if="editStep.StepConfig.requirements" wrap dense>
                            <v-col cols="12">
                                <p>Current Requirements</p>
                                <v-list-item-group v-for="(req, ix) in editStep.StepConfig.requirements" :key="ix" class="elevation-2 mb-2">
                                    <v-list-item>
                                        <v-list-item-title>
                                            <div v-if="req.type === 'Popup'">
                                                {{ req.type }} - {{ req.question }}
                                            </div>
                                            <div v-if="req.type === 'Approved Apps'">
                                                {{ req.count }} Approved Apps
                                            </div>
                                            <div v-if="req.type === 'Video'">
                                                {{ req.type }} - Wistia Media ID: {{ req.id }}
                                            </div>
                                            <div v-if="req.type === 'Lightspeed Video'">
                                                {{ req.type }} - Campaign ID: {{ req.cmp_id }}
                                            </div>
                                            <div v-if="req.type === 'Leadership Level'">
                                                {{ req.type }} - Level: {{ req.leadership_level }}
                                            </div>
                                            <div v-if="req.type === 'Contract Level'">
                                                {{ req.type }} - Level: {{ req.contract_level }}
                                            </div>
                                            <div v-if="req.type === 'Overrides'">
                                                {{ req.count }} Overrides
                                            </div>
                                            <div v-if="req.type === 'Impossible'">
                                                Impossible!
                                            </div>
                                            <div v-if="req.type === 'Quiz'">
                                                {{ req.type }} - {{ req.name }}
                                            </div>
                                        </v-list-item-title>
                                        <v-list-item-action>
                                            <v-btn icon @click.stop="removeRequirement(ix)">
                                                <v-icon>fas fa-trash-alt</v-icon>
                                            </v-btn>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item eager>
                        <v-row wrap dense>
                            <v-col cols="6">
                                <span>New Alert</span>
                                <v-select :items="alertTypes" v-model="newAlert.AlertMethod" label="Type"></v-select>
                                <v-select :items="alertRecipients" v-model="newAlert.AlertRecipient" label="Recipient"></v-select>
                                <div>Send alert at: </div>
                                <v-radio-group v-model="newAlert.AlertInterval" mandatory>
                                    <v-radio label="Step completion" value="-1"></v-radio>
                                    <v-radio label="Step start" value="0"></v-radio>
                                    <v-radio label="Days from step start" value="1"></v-radio>
                                </v-radio-group>
                                <v-text-field 
									v-if="newAlert.AlertInterval > 0"
									v-model="customAlertInterval"
									type="number" 
									label="Number of days"
								/>
                                <v-btn @click="addAlert" :disabled="isAddAlertButtonDisabled">Add Alert</v-btn>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field v-if="newAlert.AlertMethod === 'Email'" label="Subject" placeholder="Summit Notification" v-model="newAlert.AlertSubject" maxlength="500" counter></v-text-field>
                                <label>Content</label>
								<v-form v-model="smsValid" v-if="newAlert.AlertMethod === 'SMS'">
									<v-textarea 
										v-model="newAlert.AlertContent"
										counter
										:maxlength="160"
										:rules="[
											(v) => v && isOnlyAsciiExtended(v) || 'Only ASCII characters are allowed for SMS'
										]"
									/>
									<v-alert color="warning">
										SMS message is limited to 160 characters.
									</v-alert>
								</v-form>
                                <v-select v-else-if="newAlert.AlertMethod == 'Unlock Light Speed Course'" v-model="selectedContentRole" :items="contentMap"></v-select>
                                <template v-else>
									<text-editor class="summit-email-notification" v-model="newAlert.AlertContent"></text-editor>
									Merge Fields
									<q-tooltip-details>
										<div v-pre>
											{{AgentCode}}<br>
											{{AgentName}}<br>
											{{AgentID}}<br>
											{{AgentEmail}}<br>
											{{AgentFirstName}}<br>
											{{UplineAgentName}}<br>
											{{BaseShopOwnerName}}<br>
											{{BaseShopOwnerEmail}}<br>
											{{SFGDirectName}}<br>
											{{SFGDirectEmail}}
										</div>
									</q-tooltip-details>									
								</template>
                            </v-col>
                        </v-row>
                        <v-row v-if="editStep.alerts.length > 0" wrap dense class="mt-2">
                            <v-col cols="12">
                                <strong>Current Alerts</strong>
                                <v-list-item-group v-for="(alert, ix) in editStep.alerts" :key="ix" class="elevation-2 mb-2">
                                    <v-list-item>
                                        <v-list-item-title>
                                            {{ alert.AlertMethod }} to {{ alert.AlertRecipient}}, sent {{ readableInterval(alert) }} <br />
                                            <div class="summit-email-notification">
                                                <span v-html="alert.AlertContent" v-if="showContent(alert)"></span>
                                            </div>
                                        </v-list-item-title>
                                        <v-list-item-action v-if="canSendTestAlert(alert)">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn icon v-bind="attrs" v-on="on" @click.stop="alertShowSendTest = true; alertTestItem = alert">
                                                        <v-icon>fas fa-envelope</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Send a test of this alert</span>
                                            </v-tooltip>
                                        </v-list-item-action>
                                        <v-list-item-action>
                                            <v-btn icon @click.stop="confirmAlertDelete(ix)">
                                                <v-icon>fas fa-trash-alt</v-icon>
                                            </v-btn>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-col>
                        </v-row>
                        <v-dialog v-model="alertShowSendTest" :width="$vuetify.breakpoint.smAndDown ? '80%' : '50%'" persistent>
                            <v-card>
                                <v-card-text dark class="text-center pa-5">
                                    <h4>Send a test {{ alertTestItem ? alertTestItem.AlertMethod : '' }} to:</h4>
                                    <v-text-field label="Recipient" required v-model="alertTestRecipient"></v-text-field>
                                    <v-btn @click="sendTestAlert(alertTestItem, alertTestRecipient)" color="primary" class="mr-2">Send</v-btn>
                                    <v-btn @click="alertShowSendTest = false">Cancel</v-btn>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="alertShowConfirmDelete" :width="$vuetify.breakpoint.smAndDown ? '80%' : '50%'" persistent>
                            <v-card>
                                <v-card-text dark class="text-center pa-5">
                                    <p>Are you sure you want to delete this alert from the step?</p>
                                    <v-btn @click="deleteAlert(true)" color="primary" class="mr-2">Yes</v-btn>
                                    <v-btn @click="alertShowConfirmDelete = false">Cancel</v-btn>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                    </v-tab-item>
                </v-tabs>
                <v-row wrap dense>
                    <v-col cols="12" v-if="error">
                        <v-alert type="error">
                            {{ error }}
                        </v-alert>
                    </v-col>
                    <v-col cols="12" v-if="warning">
                        <v-alert type="warning">
                            {{ warning }}
                        </v-alert>
                    </v-col>
                    <v-dialog v-model="showMediaLib" width="75%">
                        <media-library v-on:usefile="useMediaFile"></media-library>
                    </v-dialog>
                    <v-dialog v-model="show_documents" width="75%">
                        <document-library :is-popup="true" :just-link="true" v-on:usefile="useDocumentFile"></document-library>
                    </v-dialog>
                    <v-col cols="12">
                        <v-btn @click="cancel">Cancel</v-btn>
                        <v-btn class="ml-4" color="primary" @click="saveStep">
                            Save
                            <v-progress-circular :disabled="saving" right size="30" v-if="saving" indeterminate></v-progress-circular>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
    </v-card>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
import QStepContentEditor from './QStepContentEditor.vue'
import ImageSelector from "@/CMS/editor/components/ImageSelector";
import MediaLibrary from '@/CMS/editor/mediaLibrary.vue';
import { EventBus } from "@/event-bus";
import DocumentLibrary from '@/CMS/editor/documentLibrary.vue'
import Level from '@/store/Models/AgentManagement/Level';
import QTooltipDetails from '@/components/utils/QTooltipDetails.vue';
import TextEditor from "@/CMS/editor/components/TextEditor";

export default {
    name: "QEditStep",
    props: ['step'],

    data() {
        return {
            saving: false,
            warning: null,
            error: null,
            valid: false,
			smsValid: true,
            showMediaLib: false,
            show_documents: false,
            mediaCallback: function() {},
            documentCallback: function() {},
            editStep: {
                ID: null,
                EnabledInd: true,
                StepConfig: {
                    requirements: [],
                    content: null,
                    badge: '',
                },
                alerts: [],
            },

            // is this the best place for type and category?
            types: [
                'Step',
                'Milestone',
                'Checkpoint',
                'Challenge',
                'Skill Block'
            ],
            categories: [
                'Base Camp',
                'Activate',
                'Accelerate',
                'Advance',
                'Achieve'
            ],

            requirementTypes: [
                'Popup',
                'Approved Apps',
                'Video',
                'Lightspeed Video',
                'Overrides',
                'Leadership Level',
                'Contract Level',
                'Impossible',
                'Quiz'
            ],

            newRequirement: {
                type: 'Popup',

                popup: {
                    title: '',
                    question: '',
                },

                apps: {
                    count: null,
                },

                video: {
                    id: '',
                },

                lightspeed_video: {
                    cmp_id : '',
                },

                leadership_level: {
                    id: '',
                },

                contract_level : {
                    id : '',
                },

                overrides: {
                    id: '',
                },

                quiz: {
                    id: '',
                    name: '',
                }
            },

            availableQuizzes: [],

            alertTypes: [
                'Email',
                'HQ Popup',
                'SMS',
                'Unlock Light Speed Course',
            ],

            alertRecipients: [
                'Agent',
                'AO',
                'SFG Direct',
                'Upline',
                'Custom',
            ],

            defaultNewAlert: {
                AlertMethod: 'SMS',
                AlertContent: '',
                AlertInterval: 0,
                AlertRecipient: 'Agent',
            },
            newAlert: {},
            customAlertInterval: 1,
            alertShowConfirmDelete: false,
            alertDeleteIndex: null,
            alertShowSendTest: false,
            alertTestItem: null,
            alertTestRecipient: null,

            contentRoles: [],
            selectedContentRole: null
        };
    },

    created() {
        QuilityAPI.getQuizzes()
            .then(resp => {
                // currently don't need all the data the resource returns
                this.availableQuizzes = resp.data.map(item => ({ id: item.ID, name: item.FormTitle }));
            })
            .catch(err => {
                this.error = err.msg;
                setTimeout(() => this.error = null, 6000);
            });
    },

    mounted() {
        this.reset();

        let that = this;
        EventBus.$on('medialibrary', function(callback) {
            that.showMediaLib = true;
            that.mediaCallback = callback;
        });
        EventBus.$on('documentlibrary', function(callback) {
            that.show_documents = true;
            that.documentCallback = callback;
        });
        this.getLevels();
        this.getContentRoles();
    },

    computed: {
        'leadership_levels': function() {
            return Level.query().where("LevelType", 'Leadership').get()
        },

        'contract_levels': function() {
            return Level.query().where("LevelType", 'Contract').get()
        },

        contentMap() {
            let contentList = []
            if (this.contentRoles.length > 0) {
                for (const role of this.contentRoles) {
                    contentList.push({
                        'text': role.contentRole,
                        'value': role.roleId
                    })
                }

            }

            return contentList
        },

		isAddAlertButtonDisabled() {
			if (this.newAlert.AlertMethod === 'SMS') {
				return !this.smsValid
			}

			return false
		}
    },

    methods: {
        reset() {
            this.editStep = { ...this.step };
            this.newAlert = { ...this.defaultNewAlert };
        },

        cancel() {
            this.$emit('close')
        },

        saveStep() {
            let that = this;
            if (!this.$refs.form.validate()) {
                this.warning = "Please correct invalid fields."
                setTimeout(function() { that.warning = null }, 6000)
                this.saving = false;
                return false;
            }

            this.saving = true;

            QuilityAPI.summitSaveStep(this.editStep)
                .then(resp => {
                    if (typeof resp.data == 'undefined') {
                        that.error = resp.msg;
                        setTimeout(() => that.error = null, 6000);
                    } else {
                        //that.editStep = resp.data;
                        that.$emit('saved', resp.data);
                    }
                    that.saving = false;
                })
                .catch(function(err) {
                    that.error = err.msg;
                    setTimeout(() => that.error = null, 6000);
                    that.saving = false;
                });
        },

        addRequirement() {
            let requirement = {};
            switch (this.newRequirement.type) {
                case 'Popup':
                    requirement = Object.assign({}, this.newRequirement.popup);
                    break;

                case 'Approved Apps':
                    requirement = Object.assign({}, this.newRequirement.apps);
                    break;

                case 'Video':
                    requirement = Object.assign({}, this.newRequirement.video);
                    break;

                case 'Lightspeed Video':
                    requirement = Object.assign({}, this.newRequirement.lightspeed_video);
                    break;

                case 'Leadership Level':
                    requirement = Object.assign({}, this.newRequirement.leadership_level);
                    break;

                case 'Contract Level':
                    requirement = Object.assign({}, this.newRequirement.contract_level);
                    break;

                case 'Overrides':
                    requirement = Object.assign({}, this.newRequirement.overrides);
                    break;

                case 'Quiz':
                    requirement = { ...this.newRequirement.quiz }
                    break;
            }

            requirement.type = this.newRequirement.type;
            this.editStep.StepConfig.requirements.push(requirement);

            this.resetNewRequirement();
        },

        removeRequirement(ix) {
            this.editStep.StepConfig.requirements.splice(ix, 1);
        },

        resetNewRequirement() {
            // create a default var to use here?
            this.newRequirement.popup.title = '';
            this.newRequirement.popup.question = '';
            this.newRequirement.video.id = '';
        },

        useMediaFile(file) {
            this.mediaCallback(file);
            this.showMediaLib = false;
        },

        useDocumentFile: function(file) {
            this.documentCallback(file.url, file.title);
            this.show_documents = false;
        },

        getLevels() {
            var g = this;
            QuilityAPI.getAgentManagement_Levels().then(function() {
                g.reset();
            })
        },

        addAlert() {
            this.newAlert.AlertInterval = parseInt(this.newAlert.AlertInterval);
            if (this.newAlert.AlertInterval > 0) {
                this.newAlert.AlertInterval = this.customAlertInterval;
            }

            if (this.selectedContentRole && this.newAlert.AlertMethod == 'Unlock Light Speed Course') {
                let selectedContentRoleData = this.contentRoles.find(roleData => roleData.roleId == this.selectedContentRole)
                this.newAlert.AlertMethod = `${this.newAlert.AlertMethod} - ${selectedContentRoleData.contentRole}`
                this.newAlert.AlertContent = selectedContentRoleData
            }

            this.editStep.alerts.push(this.newAlert);
            this.newAlert = { ...this.defaultNewAlert };
        },

        readableInterval(alert) {
            switch (alert.AlertInterval) {
                case -1:
                    return 'on step completion.';

                case 0:
                    return 'on step start.';

                case 1:
                    return alert.AlertInterval + ' day after step start.';

                default:
                    return alert.AlertInterval + ' days after step start.';
            }
        },

        confirmAlertDelete(alertIndex) {
            this.alertDeleteIndex = alertIndex;
            this.alertShowConfirmDelete = true;
        },

        deleteAlert() {
            let that = this;
            let alert = this.editStep.alerts[this.alertDeleteIndex];

            if (alert.ID !== undefined) {
                QuilityAPI.summitDeleteAlert(alert.ID)
                    .then(resp => {
                        if (typeof resp.data == 'undefined') {
                            that.error = resp.msg;
                            setTimeout(() => that.error = null, 6000);
                        } else {
                            this.editStep.alerts.splice(this.alertDeleteIndex, 1);
                            this.alertShowConfirmDelete = false;
                        }
                    })
                    .catch(function(err) {
                        that.error = err.msg;
                        setTimeout(() => that.error = null, 6000);
                    });
            } else {
                // delete an alert that hasn't been saved yet
                this.editStep.alerts.splice(this.alertDeleteIndex, 1);
                this.alertShowConfirmDelete = false;
            }
        },

        canSendTestAlert(alert) {
            // test alerts can only be sent for SMS and Email alerts that have already been saved
            return ['SMS', 'Email'].includes(alert.AlertMethod) && alert.ID !== undefined;
        },

        sendTestAlert(alert, recipient) {
            let that = this;

            QuilityAPI.summitSendTestAlert(alert.ID, recipient)
                .then(resp => {
                    if (typeof resp.data == 'undefined') {
                        that.error = resp.msg;
                        setTimeout(() => that.error = null, 6000);
                    } else {
                        this.alertShowSendTest = false;
                    }
                })
                .catch(function(err) {
                    that.error = err.msg;
                    setTimeout(() => that.error = null, 6000);
                });
        },

        async getContentRoles() {
            try {
                let roles = await QuilityAPI.getContentRoles()
                this.contentRoles = roles.data
            } catch (error) {
                this.contentRoles = null
                this.error = error.msg;
                console.log(error)
            }
        },

        showContent(alert) {
            if ((typeof alert.AlertContent).toUpperCase() == 'OBJECT') {
                return false
            }

            return true
        },

		/**
		 * Is string contain only ASCII Extended
		 * @see https://www.ascii-code.com/
		 */
		isOnlyAsciiExtended(str) {
			// Allow ASCII extended for SMS. It includes \n, so disable no-control-regex
			// eslint-disable-next-line no-control-regex
			return /^[\x00-\xFF]*$/.test(str);
		}
    },

    watch: {
        'step': {
            deep: true,
            handler: function(newValue) {
                //this.$nextTick(this.reset);
            }
        },
    },
    components: {
        TextEditor,
        QStepContentEditor,
        ImageSelector,
        MediaLibrary,
        DocumentLibrary,
        QTooltipDetails,
    }

}
</script>
<style scoped>
</style>
<style>
/* These summit-email-notification styles should come from the email template. */
.summit-email-notification h1 {
    color: #0C4463;
    font: normal normal 900 35px/50px Montserrat;
    background: inherit;
}

.summit-email-notification h2 {
    color: #46C3B2;
    font: normal normal 800 26px/38px Montserrat;
    background: inherit;
}

.summit-email-notification h3 {
    color: #46C3B2;
    font: normal normal 800 20px/30px Montserrat;
    background: inherit;
}

.summit-email-notification .button-1 {
    background: #FF5851;
    color: #FFFFFF;
    padding: 9px 23px;
    display: inline-block;
    text-decoration: none;
}
</style>