
import Draggable from "vuedraggable";
import { defineComponent, PropType } from 'vue'
import { SummitLightSpeedVideoBlock, LightSpeedVideo } from "@/CMS/types/blocks";
import LightSpeedVideoEditor from './LightSpeedVideoEditor.vue'

export default defineComponent({
    components: { 
        Draggable,
        LightSpeedVideoEditor,
    },

    props: {
        value: { type: Object as PropType<SummitLightSpeedVideoBlock['block']>, required: true },
        keyBy: { type: String as PropType<keyof LightSpeedVideo>, default: 'id' },
        editor: { type: Boolean, default: false }
    },

    emits: { 'input': (p: SummitLightSpeedVideoBlock['block']) => !!p },

    computed: {
        itemsComputed: {
            get() {
                return this.value.Videos
            },
            set(items: LightSpeedVideo[]) {
                this.$emit('input', {
                    Videos: items,
                })
            }
        }
    },

    methods: {
        deleteVideo(key: number) {
            this.itemsComputed = this.itemsComputed.filter((_, i: number) => i !== key)
        },
        addVideo() {
            this.itemsComputed = [...this.itemsComputed, {
                text: '',
                title: '',
                token: '',
                video_id: '',
            }]
        }
    }
})
