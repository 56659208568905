
import { defineComponent, PropType } from 'vue';
import { LightSpeedVideo } from '@/CMS/types/blocks/';
import TextEditor from '@/CMS/editor/components/TextEditor.vue'

export default defineComponent({
    name: "LightSpeedVideoEditor",

    components: {
        TextEditor,
    },

    props: {
        video: {
            type: Object as PropType<LightSpeedVideo>,
            required: true,
        }
    },
})
