<template>
    <v-row class="" cols="12" width="100%">
        <slot name="meta">
            <div></div>
        </slot>
        <v-row cols="12" style="min-width:100% !important;">
            <!--fix this style= later -->
            <v-col cols="12" width="100%">
                <h3 v-if="contentData.title" class="q-title text-center">{{contentData.title}}</h3>
            </v-col>
        </v-row>
        <v-row cols="12" width="100%" wrap class="d-flex" align="center" justify="center">
            <slot name="Videos">
                <v-row cols="12">
                    <v-col v-for="(video, key) in contentData.Videos" :key="key" cols="12" :sm="getSmallColumns" :md="getMediumColumns" class="ma-1">
                        <QSummitLightSpeedPlayer :video="video" />
                    </v-col>
                </v-row>
            </slot>
        </v-row>
    </v-row>
</template>
<script>
import QSummitLightSpeedPlayer from '@/CMS/views/ContentBlocks/Elements/QSummitLightSpeedPlayer.vue'

export default {
    name: "WistiaVideoGroup",

    components: { QSummitLightSpeedPlayer },

    props: {
        contentData: {
            type: Object,
            default: function() {
                return {
                    Videos: [
                        {}
                    ],
                    title: null,
                    NumColumns: 4
                }
            }
        }
    },

    computed: {
        getSmallColumns: function() {
            return Math.round(24 / this.contentData.NumColumns);
        },
        getMediumColumns: function() {
            return Math.round(12 / this.contentData.NumColumns);
        }
    },
}
</script>
