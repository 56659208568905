<template>
  <v-select :items="phases" v-model="currentPhase" label="Phase filter" />
</template>

<script setup>
import { computed } from 'vue';
import { colors } from '../phases'
import { capitalize } from 'lodash'

const phases = Object
  .entries(colors)
  .map(([key]) => ({
    text: capitalize(key),
    value: key,
  }))

const props = defineProps({
  value: { type: String, required: true },
})

const emit = defineEmits(['input'])

const currentPhase = computed({
  get() {
    return props.value
  },
  set(value) {
    emit('input', value)
  }
})
</script>
