import { render, staticRenderFns } from "./QGondolaRide.vue?vue&type=template&id=84b84b46&scoped=true&"
import script from "./QGondolaRide.vue?vue&type=script&lang=js&"
export * from "./QGondolaRide.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84b84b46",
  null
  
)

export default component.exports